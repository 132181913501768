<template>
    <div class="mb-24">
        <Payment
            :application="application"
            :document="document"
            :property="{ id: propertyId }"
        ></Payment>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Payment from '@/components/applicationService/PaymentGuarantor.vue';

export default {
    name: 'ApplicationPayment',
    components: { Payment },
    data() {
        return {
            application: null,
            document: null,
        };
    },

    computed: {
        ...mapGetters({
            getGuarantor: 'application/getGuarantor',
            getDocumentInstance: 'application/getDocumentInstance',
        }),

        propertyId() {
            return this.$route.params.propertyId || '';
        },
    },

    beforeMount() {
        if (this.getGuarantor) this.application = this.getGuarantor;
        if (this.getDocumentInstance) this.document = this.getDocumentInstance;
    },
};
</script>
