<template>
    <div class="p-4">
        <loader
            class="z-50"
            :loading="isLoading"
            :backdrop="true"
        />
        <div class="w-full bg-white flex flex-col flex-1 flex fixed bottom-0 p-4 mx-4">
            <h2 class="font-bold text-start my-4 text-base">
                Application status:
                <span class="text-purple-600">{{ application_status }}</span>
            </h2>
        </div>
        <Page
            ref="page"
            title="Rental Application"
            :loading="isLoading || isTPPWidgetVisible"
        >
            <template v-slot:header-addition>
                <div class="w-full bg-white flex flex-col flex-1 py-4 my-4">
                    <h2 class="font-bold text-start my-4 text-xl">Fees and Payments</h2>
                    <h4 class="font-bold text-gray-400 text-start text-sm w-4/5">
                        In order to finish your application process, a credit check must be preformed. This check will be done by a
                        third-party to determine application eligibility. To continue, the application fee and application deposit must be
                        collected.
                    </h4>
                </div>
            </template>
            <div class="fees">
                <h2 class="font-bold text-start my-4 text-l">Charges</h2>
                <div
                    v-for="charge in charges_codes"
                    v-bind:key="charge.id"
                    class="fee"
                >
                    <div class="fee-box">
                        <span class="fee-title">{{ charge.codeCategory.name }}</span>
                        <span class="fee-amount ml-2">${{ charge.amount }}</span>
                    </div>
                </div>
            </div>
            <div v-if="error">
                <span class="error">{{ message }}</span>
            </div>
            <div
                v-else-if="message"
                class="w-4/5 text-start self-start font-bold text-xl mt-4 text-green-700"
            >
                <p>{{ message }}</p>
            </div>
            <div
                v-if="!isLoading"
                class="btn-wrapper mb-4"
            >
                <button
                    class="btn btn-secondary"
                    @click="generateTPPAccessToken"
                >
                    Pay now
                </button>
            </div>
            <div
                id="placePayEmbedded"
                class="w-1/2 mt-3"
            ></div>
        </Page>
    </div>
    <TPPWidgetWrapper
        v-if="isTPPWidgetVisible && !mobileDevice"
        title="Application Fees Payment"
        :styles="{ height: '656px' }"
    />
</template>

<script>
import Event from '@/utils/EventBus.js';
import Page from '@/components/ui/Page';
import NotifyMixin from '@/mixins/NotifyMixin';
import Loader from '@/components/ui/Loader';
import { mapActions, mapGetters } from 'vuex';
import TriplePlayPayMixin from '@/mixins/TriplePlayPayMixin';
import TPPWidgetWrapper from '@/components/payments/TPPWidgetWrapper';
import AccountingQueryManager from '@/api/accounting/accountingQueryManager';
import { PAYMENT_METHODS_MAP } from '@/utils/constants/accounting';

export default {
    name: 'Payment',
    components: { Page, Loader, TPPWidgetWrapper },
    mixins: [NotifyMixin, TriplePlayPayMixin, AccountingQueryManager],
    props: {
        property: {
            type: Object,
            required: true,
        },

        application: {
            type: Object,
            required: true,
        },

        document: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isLoading: false,
            error: false,
            message: '',
            account: null,
            application_status: 'Application not Submitted',
            charges_codes: null,
            payment_total_fee: 0,
            charge_amounts: [],
            occupancy: null,
            application_record: null,
            paymentData: {
                status: 'processing',
                paymentMethodType: 'credit card',
                accrualAmount: null,
                paymentAmount: null,
                paymentCurrency: 'USD',
                paymentDate: null,
                transactionId: null,
            },
        };
    },

    computed: {
        ...mapGetters({
            getApplication: 'application/getApplication',
            getDocumentInstance: 'application/getDocumentInstance',
            currentProfile: 'auth/currentProfile',
            isTPPWidgetVisible: 'accounting/isTPPWidgetVisible',
        }),

        customTPPPaymentConfig() {
            const amount = Number(this.payment_total_fee);
            return {
                paymentType: 'charge',
                amount,
                paymentOptions: ['credit_card'],
            };
        },
    },

    mounted() {
        Event.on('reset-modal', () => {
            this.isLoading = false;
        });
    },

    async beforeMount() {
        await Promise.all([this.fetchCurrentApplicationByContactId(this.currentProfile.contactId), this.fetchChargeCodes()]).catch(() => {
            this.notifyError('Something went wrong. Please try again later');
        });
    },

    methods: {
        ...mapActions({
            setApplication: 'application/setApplication',
        }),

        async processPaymentDataBasedOnStatus(data) {
            const isPaymentSuccessful = data?.action === 'success';
            this.paymentData.full_name = `${this.application?.applicant_first_name} ${this.application?.applicant_last_name}`;
            this.paymentData.paymentDate = new Date().toISOString();
            this.paymentData.email = this.application?.email;
            this.paymentData.balance = `$${Number(this.payment_total_fee)}`;
            this.paymentData.payment = Number(this.payment_total_fee);
            this.paymentData.ref_number = null;
            this.paymentData.paymentMethodType = PAYMENT_METHODS_MAP[data?.payload?.method];
            if (isPaymentSuccessful) {
                this.message = 'Payment successful';
                this.application_status = 'Background Screening';
                this.paymentData.balance = '$0';
                this.paymentData.payment_status = 'COMPLETE';
                this.paymentData.ref_number = data?.payload?.transaction_id;
                /* This will communicate with an Accounting endpoint */
                this.savePayment({ ...this.paymentData }, true);
            } else {
                this.error = true;
                this.paymentData.payment_status = 'FAILED';
                this.message = 'Transaction declined. Please try again.';
                this.paymentData.ref_number = Math.floor(Math.random() * 9999999999999999);
                this.paymentData.reason = 'Card declined / Invalid';
                Event.emit('reset-modal');
                this.savePayment({ ...this.paymentData }, 'Card declined / Invalid');
            }
        },

        async updateApplicationRecord() {
            const new_application = { ...this.getApplication };
            const currentPerson = new_application.persons?.find(person => person.contact_id === this.currentProfile.contactId);
            const currentParty = new_application.parties?.find(party => party.contact_id === this.currentProfile.contactId);
            delete new_application.application_id;
            delete new_application.occupancy_id;
            delete new_application.person_id;
            await this.$leasingServiceDataProvider
                .patch('application', {
                    application_id: this.application_record.application_id,
                    data: {
                        occupancy_id: this.currentProfile.occupancyId,
                        household_id: new_application.household_id,
                        business_unit_id: new_application.business_unit_id,
                        customer_id: new_application.customer_id,
                        submission_date: this.getDocumentInstance.updated_at,
                        document_url: this.getDocumentInstance.document_url,
                        contact_id: this.getApplication.contact_id,
                        application_process_status: 'Identity verification',
                        event_description: 'Identity verification process - started',
                        event_name: 'Identity verification process has started',
                        is_primary: currentParty?.is_primary,
                        person_role: currentPerson?.person_role,
                    },
                })
                .then(() => {
                    this.$router.push({
                        name: 'application_service.background_screening_guarantor',
                        params: { propertyId: this.$props.property.id },
                    });
                })
                .catch(() => {
                    this.notifyError('Something went wrong updating the application.');
                });
        },

        // prettier-ignore
        savePayment(payment_info, isPaid = false, reason = '') {
            this.isLoading = true;

            this.charges_codes.forEach(async charge => {
                await this.$accountingServiceDataProvider
                    .create('application_fee', {
                        accountId: this.currentProfile.occupancyId,
                        headers: {
                            customerId: this.currentProfile.customerId,
                            communityId: this.currentProfile.communityId,
                        },
                        data: {
                            codeId: charge?.id,
                            reference: `${payment_info.ref_number}`,
                            payment:
                                payment_info.payment_status === 'FAILED'
                                    ? {
                                        status: payment_info.payment_status,
                                        paymentMethodType: 'credit card',
                                        accrualAmount: Number(charge.amount),
                                        paymentAmount: Number(charge.amount),
                                        paymentCurrency: 'USD',
                                        paymentDate: payment_info.paymentDate,
                                        transactionId: `${payment_info.ref_number}`,
                                        reason: payment_info.reason,
                                        payerId: this.application_record.person_id,
                                    }
                                    : {
                                        status: payment_info.payment_status,
                                        paymentMethodType: 'credit card',
                                        accrualAmount: Number(charge.amount),
                                        paymentAmount: Number(charge.amount),
                                        paymentCurrency: 'USD',
                                        paymentDate: payment_info.paymentDate,
                                        transactionId: `${payment_info.ref_number}`,
                                        payerId: this.application_record.person_id,
                                    },
                        },
                    })
                    .then(async () => {
                        this.message = '';
                        if (isPaid) this.updateApplicationRecord();
                        else if (reason !== '') this.notifyError(reason);
                    })
                    .catch(() => {
                        this.notifyError('Something went wrong saving your payment, please try again later');
                    })
                    .finally(() => (this.isLoading = false));
            });
        },

        async fetchChargeCodes() {
            this.isLoading = true;
            await this.$accountingServiceDataProvider
                .get('charge_codes', {
                    headers: {
                        customerId: this.currentProfile.customerId,
                        communityId: this.currentProfile.communityId,
                    },
                    sortDirection: 'DESC',
                    sortField: 'id',
                    chargeCodeCategoryAlias: 'applicant-fee',
                    codeType: 'receivable',
                })
                .then(res => {
                    this.charges_codes = res.data;
                    res.data.forEach(charge => {
                        this.charge_amounts.push(Number(charge.amount));
                    });
                })
                .catch(() => {
                    this.notifyError('Something went wrong fetching the charge codes, please try again later');
                })
                .finally(() => (this.isLoading = false));

            this.payment_total_fee = this.charge_amounts.reduce((partialSum, a) => partialSum + a, 0);
        },

        async fetchCurrentApplicationByContactId(contact_id) {
            await this.$leasingServiceDataProvider
                .getOne('application', {
                    contact_id,
                })
                .then(async res => {
                    this.application_record = res[0];
                })
                .catch(error => {
                    this.notifyError(error.message);
                });
        },
    },
};
</script>

<style scoped>
.fees {
    width: 80%;
    margin: 2.5rem 0 2.5rem;
}
.message {
    text-align: center;
    margin: 1rem 0;
    font-weight: 700;
}
.fees > .fee {
    background: #f5f5f5;
    border: 1px solid #ededed;
    border-radius: 6px;
    padding: 0.75rem 1.25rem;
    align-items: center;
    max-width: 100%;
}
.fees > .fee > .fee-box {
    display: flex;
    justify-content: space-between;
}
</style>
